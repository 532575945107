<template>
  <div class="proposal__wrap">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs__wrap">
      <el-breadcrumb-item :to="{ path: '/company' }">企业管理</el-breadcrumb-item>
      <el-breadcrumb-item>账号信息</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="section shadow filter__wrap">
      <el-radio-group v-model="table.params.type" @change="getTable">
        <el-radio-button :label="1">充值明细</el-radio-button>
        <el-radio-button :label="2">消费明细</el-radio-button>
      </el-radio-group>
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="table.params.type ===1 ? field : field2"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:type>
          <span>{{table.params.type === 1 ? '人才币充值' : '购买简历'}}</span>
        </template>
      </VTable>
    </div>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'FacultyList',
  mixins:[mixinTable],
  components: {
    VTable,
  },
  data() {
    return {
      field: [
        { name: "type", label: "交易类型", hidden: false },
        { name: "amount", label: "充值金额(元)", hidden: false },
        { name: "coin", label: "人才币数量", hidden: false },
        { name: "order_no", label: "订单编号", hidden: false },
        { name: "create_time", label: "充值时间", hidden: false },
      ],
      field2: [
        { name: "type", label: "交易类型", hidden: false },
        { name: "coin", label: "交易金额(元)", hidden: false },
        { name: "order_no", label: "订单编号", hidden: false },
        { name: "create_time", label: "充值时间", hidden: false },
      ],
      table: {
        loading: false,
        params: {
          type: 1,
          company_id: sessionStorage.getItem('companyId'),
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/company/tradeLog', { params: this.table.params }).then(res => {
        if(res.code === 1) {
           this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    pushPage(row) {
      sessionStorage.setItem('facultyId', row.id)
      this.$router.push({
        path: '/major',
        query: {
          facultyId: row.id,
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }

    .crumbs__wrap {
      margin-bottom: 20px;
    }
  }
</style>